import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import postStyles from './Post.module.scss';

const Post = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          topic
        }
      }
    }
  `);

  const {
    postWrap,
    titleWrap,
    publishedDate,
    title,
    topic,
    contentWrap,
  } = postStyles;

  return (
    <div>
      <header className={titleWrap}>
        <div className={publishedDate}>May 3, 2020</div>
        <h1 className={title}>{data.site.siteMetadata.title}</h1>
        <div className={topic}>Programming</div>
      </header>
      <div className={postWrap}>
        <main className={contentWrap}>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione
            nihil veniam quasi voluptas, facere error, eaque porro quis
            voluptate quia, unde ipsum eveniet reprehenderit molestias.
          </p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis
            maiores laborum deserunt asperiores facere, qui aliquid numquam,
            voluptatum delectus alias voluptas ducimus natus. A, doloremque!
            Repellat, quas. Ab, iste quas. Aut voluptatem odio recusandae quo
            corporis culpa consequuntur, ad et!
          </p>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam
            doloremque harum atque aperiam distinctio provident culpa iusto
            omnis, exercitationem nulla. Possimus, unde quae.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex modi
            deleniti ipsa omnis eveniet! Perferendis necessitatibus explicabo
            ratione impedit in totam rem, commodi, provident, alias ad error
            excepturi quidem optio!
          </p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error
            inventore facere porro quasi fugiat?
          </p>
        </main>
      </div>
    </div>
  );
};

export default Post;
