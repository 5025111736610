import React from 'react';

import Layout from '../../components/Layout';
import BlogLayout from '../../components/Layout/BlogLayout';
import Post from '../../components/Blog/Post';

const BlogPostPage = () => {
  return (
    <Layout>
      <BlogLayout>
        <Post />
      </BlogLayout>
    </Layout>
  );
};

export default BlogPostPage;
